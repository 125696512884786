// Forms Generic
// -----------------------------------------------------
form {
    height: min-content;
}
.form{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .form-header{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .form-fields {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 10px;
        margin: 10px 0 10px 0;
    }
    .form-message {
        margin: 0 0 10px 0;
    }
    .form-buttons{
        margin-top: 5px;
        display: flex;
        gap: 10px;
        // justify-content: space-between;
        align-items: center;
    }
}
.form-field {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    .input, .MuiFormControl-root {
        width: 100%;
    }
}
.MuiInput-input, .MuiInputLabel-root, .MuiFormLabel-root {
    // font-size: 12px !important;
}
.field-select .MuiInput-root {
    height: 26px;
}
.field-placeholder {
    color: #b2b2b2;
}

.MuiFormHelperText-root {
    margin-left: 0 !important;
    white-space: pre-line
}
.help-text {
    display: block;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6);
}
.warning{
    min-height: 16px;
    color: $error_main;
    font-size: 12px;
    margin: 0;
}


// Forms Specific
// -----------------------------------------------------
.form-login {
    width: 250px;
    // padding-bottom: 10px;
    .MuiButtonBase-root{
        width: 100%;
        display: flex;
    }
    .form-footer {
        display: grid;
        gap: 10px;
    }
}

.form-search {
    width: 100%;
    height: 28px;
    max-width: 240px;
    * {
        transition: all 0.5s;
    }
    input {
        width: 100%;
        box-sizing: border-box;
        font-weight: 200 !important;
        color: #f4f4f4;
        background-color: #323232;
        padding: 6px 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: none;
        outline: none;
    }
    .search-results {
        max-height: 300px;
        overflow-y: auto;
        z-index: 1000;
        position: relative;
        background-color: #323232;
        width: 100%;
        box-shadow: 0 5px 10px rgb(0, 0, 0);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        hr {
            width: auto;
            margin: 0 8px;
        }
        .search-result-group-heading {
            font-size: 10px;
            padding: 4px 10px 0px 18px;
            display: flex;
            align-items: center;
            color: #bdbdbd;
            // font-weight: 500;
            text-transform: capitalize;
            svg {
                color: #bdbdbd !important;
                // margin-right: 3px;
                font-size: 12px;
            }
        }
        .search-result {
            padding: 8px 10px;
            font-size: 12px;
            cursor: pointer;
            display: flex;
            color: #f4f4f4;
        }
        .search-result:hover {
            padding-left: 10px !important;
            background-color: #00000005;
        }
    }
}
.form-search-light {
    color: white;
    input {
        background-color: #f5f5f5;
        color: #323232;
    }
    .search-results {
        background-color: white !important;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.186);
        .search-result {
            color: #323232;
        }
    }
}

.form-information-request {
    .question-details {
        margin-bottom: 25px;
        .title {
            font-size: 11px;
            color: $primary_main;
            margin-bottom: 6px;
            cursor: pointer;
        }
        .question-text {
            font-weight: 600;
            font-size: 12px;
            margin: 15px 0 0 0;
            color: #191919;
        }
    }
    .suffix {
        // background-color: #f7f7f7;
        padding: 2px;
        margin-bottom: 8px;
    }
    .submit-button {
        margin: 15px 0 20px;
        min-width: 60px !important;
    }
}


// Fields
// -----------------------------------------------------
.field-slider {
    max-width: 325px;
    .MuiSlider-root {
        padding: 5px 0;
        .MuiSlider-track {
            background-color: transparent !important;
        }
        .MuiSlider-thumb {
            width: 10px;
            height: 10px;
        }
    }
    .field-slider-label {
        font-size: 12px !important;
        color: #3d3d3d;
    }
    .slider-labels {
        display: flex;
        justify-content: space-between;
        .slider-label {
            font-size: 10px;
            text-transform: capitalize;
        }
    }
}

// File upload
.field-file {
    // max-width: 325px;
    display: grid;
    gap: 10px;
    margin-bottom: 20px;
    .field-file-input {
        width: 100%;
        display: flex;
        align-items: start;
        gap: 10px;
        margin: 10px 0 0 15px;
        label {
            cursor: pointer;
            color: #2d2d2d !important;
            svg {
                color: #828282;
            }
        }
        label:hover {
            color: $primary_main;
            svg {
                color: $primary_main;
            }
        }
        input {
            outline: none;
            font-size: 16px;
            border: none;
            padding: 0;
        }
        input::placeholder {
            color: $info_main;
        }
        input:hover::placeholder, input:focus::placeholder {
            color: rgb(36, 36, 36);
        }
    }
}

// Checkbox
.field-checkbox {
    max-width: none !important;
    label {
        width: initial;
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer !important;
    }
    input {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    input:disabled {
        opacity: 0.9;
    }
}
.field-checkbox-select {
    .MuiInputLabel-root {
        font-size: 14px;
    }
    .checkbox-select-list {
        margin: 5px 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
.field-radio-select {
    max-width: none !important;
}
.field-checkbox, .field-switch, .field-radio-select{
    color: $info_dark;
    label {
        // margin: 0 !important;
        color: #2d2d2d !important;
        span {
            // font-size: 12px;
            cursor: pointer;
        }
    }
    input {
        // width: 12px;
        // height: 12px;
        // min-width: 12px;
        // min-height: 12px;
        // margin: 0;
        // margin-right: 6px;
    }
    .MuiRadio-root{
        .MuiFormControlLabel-label {
            // font-size: 10px !important;
        }
        svg{
            // font-size: 14px !important;
        }
    }
}
.field-select {
    width: -webkit-fill-available;
    max-width: 325px;
}
.field-date {
    display: grid;
    font-size: 12px;
    input {
        font-size: 15px;
        outline: none !important;
        border: none !important;
        border-bottom: 0.5px solid grey !important;
    }
}
.field-large-list-select-minimised {
    display: grid;
    margin-bottom: 3px;
    gap: 3px;
    label {
        font-size: 12px !important;
    }
}
.field-large-list-select {
    label {
        font-size: 12px !important;
    }
    .list-filter {
        display: flex;
        align-items: center;
        color: $info_main;
        margin-bottom: 5px;
        // background-color: $light-grey-background-color;
        svg {
            font-size: 14px;
        }
        input {
            width: 100%;
            border: none;
            outline: none;
            // background-color: white;
            border-bottom: 0.5px solid rgb(158, 158, 158) !important;
            padding: 4px 4px;
            box-sizing: border-box;
            z-index: 1;
            font-size: 12px;
            line-height: 10px;
            caret-color: rgba(0, 0, 0, 0.316);
        }
    }
    .selected-items {
        min-height: 19px;
        overflow-wrap: anywhere;
        .item {
            color: white;
            background-color: $primary_main;
            padding: 1px 5px 2px 8px;
            font-size: 12px;
            border-radius: 10px;
            margin: 0 4px 0 0;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            svg {
                font-size: 12px;
                margin-left: 3px;
            }
        }
    }
    .large-list-select-list {
        margin: 0 0 5px 0;
        box-shadow: 0 0 10px 0 #00000017;
        .focused {
            background-color: #f0f0f0 !important;
        }
        .selected {
            color: $primary_dark;
            font-weight: 600;
        }
        .ReactVirtualized__Table .ReactVirtualized__Table__row {
            background-color: transparent;
            transition: background-color 0s;
        }
        .ReactVirtualized__Table__row:hover {
            background-color: whitesmoke;
        }
        .ReactVirtualized__Grid:focus {
            outline: 2px solid $primary_main !important;
        }
    }
}
.field-automation-select {
    display: grid;
    gap: 8px;
    .selected {
        .option-header svg {
            transform: rotateZ(90deg);
        }
        .option-details {
            height: 269px !important;
        }
    }
    .automation-option {
        .option-header {
            background: #ececec;
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            color: $primary_main;
            cursor: pointer;
            svg {
                font-size: 18px;
                transition: all 0.5s;
            }
            * {
                cursor: pointer;
            }
        }
        .option-details {
            height: 0;
            overflow: hidden;
            transition: all 0.5s;
            img {
                width: 100%;
                height: auto;
                margin-top: 5px;
                box-shadow: #00000026 0px 0px 10px 0px;
            }
        }
    }
}

// Buttons
// -----------------------------------------------------
.MuiButtonGroup-root{
    border-radius: 0 !important;
}
.button-group {
    display: flex;
    gap: 10px
}
button, .btn, .MuiButtonBase-root{
    height: fit-content;
    width: max-content;
    // align-items: baseline !important;
    min-width: min-content !important;
    border-radius: 3px !important;
    display: flex !important;
    align-items: center !important;
    font-weight: 400 !important;
    text-transform: none !important;
    white-space: nowrap;
    gap: 3px;
    *, img, svg, path {
        cursor: pointer;
    }
    path{
        cursor: pointer;
    }
    .MuiCircularProgress-root{
        width: 10px !important;
        height: 10px !important;
    }
}
.MuiButton-text {
    padding-left: 1px !important;
    padding-right: 1px !important;
}
.MuiIconButton-root {
    border-radius: 100% !important;
}
.MuiIconButton-root{
    border-radius: 100px;
}
.MuiButtonGroup-grouped:not(:last-of-type) {
    border: none !important;
    margin-right: 12px;
}
.MuiIconButton-root, .MuiButton-textInfo {
    color: rgb(92, 92, 92) !important;
    svg {
        color: rgb(92, 92, 92);
    }
}
.tool-drawer {
    .MuiIconButton-root, .MuiButton-textInfo {
        color: rgb(230, 230, 230) !important;
        svg {
            color: rgb(224, 224, 224);
        }
    }
}
.btn-oauth {
    width: fit-content !important;
    margin: 12px auto !important;
    img {
        width: 12px;
        margin-right: 10px;
    }
}
.btn-xs {
    height: 18px;
    min-width: 18px !important;
    padding: 0px 4px 0px 5px !important;
    font-size: 10px !important;
    display: inline;
    // display: grid;
    // grid-gap: 3px;
    svg{
        font-size: 12px !important;
    }
}
.btn-sm {
    height: 21px;
    min-width: 21px !important;
    padding: 3px 10px 2px !important;
    font-size: 10px !important;
    svg{
        font-size: 12px !important;
    }
}
.btn-md {
    height: 25px;
    min-width: 25px !important;
    padding: 4px 14px 3px !important;
    font-size: 12px !important;
    svg{
        font-size: 14px !important;
    }
}
.btn-lg {
    height: 28px;
    min-width: 28px !important;
    padding: 8px 18px !important;
    font-size: 16px !important;
    svg{
        font-size: 18x !important;
        color: inherit;
    }
}
.btn-no-padding {
    padding: 0 !important;
}
.btn-icon {
    // min-width: 14px !important;
    padding: 0px !important;
    border-radius: 20px !important;
}

.btn-lowercase {
    text-transform: none !important;
}
