// Variables
// -----------------------------------------------------
$theme-color: #243383; 
$theme-color-pale: rgb(204, 207, 213);
$light-border: 0.5px solid #eceef3;
$text-light: rgb(230, 230, 230);
$light-grey-background-color: #f0f0f0;

// Paste from Theme.ts
// -----------------------------------------------------
$primary_dark:#243657;
$primary_main:#314A94;
$primary_light:#6D8DC1;
$primary_contrastText:#ffffff;

$secondary_dark:#D67D11;
$secondary_main:#FF910A;
$secondary_light:#FFAC47;
$secondary_contrastText:#ffffff;

$info_dark:#585c67;
$info_main:#6e7482;
$info_light:#8d9098;
$info_contrastText:#ffffff;

$error_main:#c76466;
$error_dark:#c24b4e;
$error_light:#e87a7d;
$error_contrastText:#ffffff;

$success_dark:#548755;
$success_main:#6fa871;
$success_light:#8eba8f;
$success_contrastText:#ffffff; 

$warning_dark:#D67D11;
$warning_main:#FF910A;
$warning_light:#FFAC47;
$warning_contrastText:#ffffff;
// -----------------------------------------------------
// Paste from Theme.ts

.st0{fill:#334756;}
	.st1{fill:#CC5A5C;}

* {
	font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    transition: 0.5s all;
}

body {
	margin: 0;
	padding: 0;
}

header {
	height: 65px;
	// background-color: rgb(230, 230, 230);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.107);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

main {
	padding: 40px 0;
    display: flex;
    grid-template-columns: auto auto;
    gap: 20px;
}

.main-inner {
	width: 100vw;
	max-width: 1000px;
	margin: auto;
	padding: 0 25px;
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
    box-sizing: border-box;
}

.embedded {
    main {
        padding: 0;
    }
    .main-inner {
        padding: 0;    
    }
}

small {
	font-size: 12px;
}

button {
	cursor: pointer;
}

h1,h2,h3,h4,h5,h5 {
	padding: 0;
	margin: 0;
}

.link {
	cursor: pointer;
	text-decoration: underline !important;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.MuiMenuItem-root {
	width: 100% !important;
}

.modal {
    display: flex;
    height: 100vh;
    .modal-body {
        max-width: 80vw;
        min-width: 300px;
        height: min-content;
        margin: auto;
        background-color: white;    
        box-shadow: 0 0 20px 0 #00000085;
        padding: 25px;
    }
}


.trunc-ellipsis {
    width: auto;
    max-width: max-content;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.trunc-measurer {
    opacity: 0;
    top: -1000;
    z-index: -100;
    position: fixed;
    width: fit-content;
}
.trunc-revealer {
    width: min-content;
    max-width: 300px;
    min-width: 170px;
    position: fixed;
    z-index: 1000;
    padding: 3px 5px;
    font-size: 10px;
    word-wrap: normal;
    background: white;
    color: rgb(34, 34, 34);
    box-shadow: 2px 2px 20px 0 #0000004f;
    transition: all 0s;
    white-space: normal;
}

.error-message-list {
    display: grid;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    align-items: end;
    .error-message {
        width: min-content;
        text-wrap: nowrap;
        margin-left: auto;
        background-color: $error_light;
        color: white;
        padding: 2px 8px 2px 12px;
        font-size: 14px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        button {
            padding: 0;
        }
        svg {
            font-size: 16px;
            color: white;
        }
    }
}

@media only screen and (max-width: 600px) {
    .main-inner {
        padding: 0 10px;
    }
}


// Scrollbar
// -----------------------------------------------------
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer !important;
}
::-webkit-scrollbar-track {
    background: rgba(119, 119, 119, 0.107);
    border-radius: inherit;
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.145) inset;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(196, 196, 196);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgb(168, 168, 168);
}