.questionnaire {
    width: 100%;
    max-width: 600px;
    display: grid;
    gap: 15px;

    .questionnaire-header {
        display: flex;
        align-items: center;
        gap: 10px;
        color: black;
    }
}

.questionnaire-page {
    margin: 30px 0;
    display: grid;
    gap: 20px;
    form {
        // max-width: 360px;
    }
}

.previous-answers {
    display: grid;
    gap: 20px;
    margin-top: 30px;
    button {
        height: auto;
        width: auto;
        text-wrap: auto;
        text-align: left;
        padding-left: 0 !important;
    }
}

.upload-list {
    padding: 10px 0 0 10px;
    display: grid;
    gap: 10px;
    .upload-list-item {
        display: flex;
        gap: 10px;
        color: #5b5959;
        align-items: center;
        cursor: pointer;
        svg {
            font-size: 18px !important;
        }
    }
}

.dev-tools {
    width: 100%;
}

.table {
    width: 100%;
    // max-width: 100vw;
    font-size: 14px !important;
    .table-headings {
        display: flex;
        font-weight: 600;
        background-color: whitesmoke;
    }
    .table-body {
        box-shadow: 0 -10px 10px -11px #0000001f inset;
        .table-row {
            display: flex;
        }
    }
    .table-cell {
        display: grid;
        padding: 5px;
        flex: 1 1 0px;
    }
}